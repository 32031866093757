import React from 'react';
import Image1 from '../../assets/img/screen-six/hr.webp';
import "../componant.css";

function HrAbout(){
    return(
        <div className='container'>
            <div className="row">
                <div className="col-md-6 about-left">
                    <img src={Image1} alt='About' width={'82%'} />
                </div>
                
                <div className="col-md-6 about-right" style={{backgroundColor:'#EFF2F6'}}>
                    {/* <h2 className="about-top-hr title-color">Message from the COO</h2> */}
                    <p className='about-top-hr desc-color'>Welcome to Yethi, where every day is an opportunity to innovate, collaborate, 
                        and make a meaningful impact. It isn't just a workplace; it's a dynamic ecosystem that fosters growth, 
                        celebrates diversity, and embraces the spirit of curiosity. 
                    </p>

                    <p className='desc-color'>At Yethi, we believe in creating a culture where every individual's potential is 
                        recognized and nurtured. Here, your ideas matter, your voice is heard, and your ambitions are supported. 
                        We understand that our success is a reflection of the collective brilliance of our team, and that's why 
                        we invest in our people.
                    </p>
                    <a href="https://yethi.oorwin.com/careers/index.html#/list" target='_blank' rel="noreferrer" className='butn'>View Open Positions</a>
                </div>
            </div>
        </div>
    );
}
export default HrAbout;