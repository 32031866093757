import React from "react";

function Notes(){
    return(
        <div className="container">
            <div className="notes-left py-5">
                <h3 style={{color:'#3A62C2'}}>At Yethi, we're not just building a team; we're building a family of innovators. Join us, and let's create the future together.</h3>
            </div>
        </div>
    );
}
export default Notes;