import React from 'react';
import Expect1 from '../../assets/img/screen-six/expect-1.webp';
import Expect2 from '../../assets/img/screen-six//expect-2.webp';
import Expect3 from '../../assets/img/screen-six/expect-3.webp';
import Expect4 from '../../assets/img/screen-six/expect-4.webp';
import "../componant.css";

function Expect(){
    return(
        <div className='container pt-5'>
            <div className='expect-left'>
                <div>
                    <h3 className='title-color'>What to Expect at Yethi</h3>
                </div>
                <div className='row'>
                    <div className='col-md-6 col-12'>
                        <div className='row carer'>
                            <div className='col-sm-5 col-12'>
                                <img src={Expect1} />
                            </div>
                            <div className='col-sm-7 col-12'>
                                <h5 className='title-color'>More than a Company</h5>
                                <p>An innovation arena where challenges fuel creativity, boundaries are surpassed, 
                                    and contributions redefine the future of technology.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-6 col-12'>
                        <div className='row carer'>
                            <div className='col-sm-5 col-12'>
                                <img src={Expect2} />
                            </div>
                            <div className='col-sm-7 col-12'>
                                <h5 className='title-color'>Collaborative Spirit</h5>
                                <p>Fueling creativity through an open and collaborative workspace where mentors, 
                                    teammates, and leaders share your passion for success.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-6 col-12'>
                        <div className='row carer'>
                            <div className='col-sm-5 col-12'>
                                <img src={Expect3} />
                            </div>
                            <div className='col-sm-7 col-12'>
                                <h5 className='title-color'>Learning Culture</h5>
                                <p>We promote continuous learning by providing resources and opportunities to keep you ahead in the industry.</p>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-6 col-12'>
                        <div className='row carer'>
                            <div className='col-sm-5 col-12'>
                                <img src={Expect4} />
                            </div>
                            <div className='col-sm-7 col-12'>
                                <h5 className='title-color'>Inclusion and Diversity</h5>
                                <p>Embracing the strength of diverse perspectives, where every individual, regardless of 
                                    background or identity, thrives and contributes to our collective success.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Expect;