import React from "react";
import "../componant.css";
import Image1 from "../../assets/img/screen-six/explore.webp";

function Innovate(){
    return(
        <div className="container">
            <div className="innovate-left">
                <div className="row py-5 mt-3">
                    <div className="col-md-6 col-12">
                        <h3 className="title-color">Let’s Innovate Together</h3>
                        <p >It takes an amazing team to build something with real impact. We are on a mission to build the next generation
                             of solutions to improve the quality of digital technologies being adopted within the financial sector.<br/> 
                             Join our diverse team of professionals to help reshape the Application Landscape in the BFSI industry.
                        </p>
                        {/* <a href="#" className="butn">KNOW MORE</a> */}

                        <h3 className="title-color pt-3">Explore Opportunities</h3>
                        <p className="mb-4">Ready to embark on a journey where your career meets unlimited possibilities? Explore our current openings 
                            and find the perfect fit for your skills and aspirations.<br/><br/>
                            If your area of interest is not listed above, don’t hesitate to send over your CV to us. We will reach out to you 
                            the moment we have something that is in line with your interests!
                        </p>
                        <a href="https://yethi.oorwin.com/careers/index.html#/list" target='_blank' rel="noreferrer" className="butn">View Open Positions</a>
                    </div>

                    <div className="col-md-6 col-12">
                        <img src={Image1} alt="" className="innovate" />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Innovate;