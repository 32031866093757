import React from 'react';
import Stories1 from '../../assets/img/home/stories.webp';
import Stories2 from '../../assets/img/home/stories1.webp';
import Stories3 from '../../assets/img/home/stories2.webp';
import "../About/home-one/index.css";
import ReactPlayer from 'react-player';

function Stories(){
    return(
        <div className='py-5' >
            <div className='container'>
                <div className='story career-story'>
                    <h3 style={{color:'#606060'}}>Success Stories</h3>
                    <p style={{color:'#606060'}}>Leading banks and financial Institutions rely on our assurance expertise for Business 
                        success.
                    </p>
                </div>

                <div className='row'>
                    <div className='col-md-4'>
                        <img src={Stories1} alt='' />
                        {/* <ReactPlayer className="react-player__preview react-player__shadow react-player__play-icon" 
                            url="https://youtu.be/SfqqDg6a4qg?si=4x-w8cIIr9uG0B5D"
                            light={Stories1}
                        /> */}
                        <p>A leading multinational private Bank fast-tracks Finacle upgrade and cuts TAT by 80% with Tenjin </p>
                        <a href='https://yethi.in/finacle-upgrade-tat-tenjin/' target='_blank'>Read the case study</a>
                    </div>

                    <div className='col-md-4 case'>
                        <img src={Stories2} />
                        <p>Greek Bank Leverages Yethi’s Testing Services to Maximize Solution Performance Across Two of its European Subsidiaries </p>
                        <a href='https://yethi.in/greek-bank-maximize-performance/' target='_blank'>Read the case study</a>
                    </div>

                    <div className='col-md-4 case'>
                        <img src={Stories3} /> 
                        <p>Yethi validates reliability of the Fraud Management Solution for a bank in India</p>
                        <a href='https://yethi.in/yethi-validates-reliability-fraud-management-solution/' target='_blank'>Read the case study</a>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Stories;