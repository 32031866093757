import React from 'react';
import quoteIcon from '../../../assets/img/screen-six/quote.png'

function TestimonialItem(props) {
    return (
        <div className="testimonial-item testimonial-item--3">
            {/* <div className="testimonial-thumb">
                <img src={require('../../../assets/img/' + props.authorThumb)} alt="Businex"/>
            </div> */}

            <div className="">
                <div className='quote'>
                    <img src={quoteIcon} alt="Businex"/>
                    <p>{props.quote}</p>
                </div>
                
                <div className="quote-img">
                    {/* <img src={require('../../../assets/img/' + props.authorThumb)} className='testi-thumb' alt="Author Image"/> */}
                    <div>
                        <h6 className="client-name">{props.author}</h6>
                        <p>{props.desig}</p>
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default TestimonialItem;