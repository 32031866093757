import React from 'react';
// import ReactDOM from 'react-dom';
import App from './App';
import './index.scss'
import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from "react-helmet-async";

import ReactDOM from 'react-dom/client';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <HelmetProvider>
      <App />
    </HelmetProvider>
);

// ReactDOM.render( root, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
