import React from 'react';

import Header from '../components/Header';
import Banner from "../components/ScreenSix/banner";
import HrAbout from '../components/ScreenSix/hr-about';
import Expect from '../components/ScreenSix/expect';
import Why from '../components/ScreenSix/why';
import Innovate from '../components/ScreenSix/innovate';
import Testimonial from '../components/ScreenSix/testimonial';
import Stories from '../components/ScreenSix/stories';
import Notes from '../components/ScreenSix/note';
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import { Helmet } from "react-helmet-async";

const ScreenSix = () => {
    return (
      <div className="screenSix-container">
        <Helmet prioritizeSeoTags>
          <title>Careers Page</title>
          <meta name="description" content="Careers Page Description" />
          <link rel="canonical" href="https://yethi.in/careers" />
        </Helmet>
        
        <Header />
        <Banner />
        <HrAbout />

        <div className='container'>
          <iframe
            title='career-iframe'
            src="https://yethi.oorwin.com/careers/index.html"
            width="100%"
            height="1200"
            iframeBorder="0"
            style={{ border: "none", margin: "0px", marginTop: "50px" }}
          ></iframe>
        </div>
              
        <Expect />
        <Why />
        <Innovate />
        <Testimonial />
        <Stories />
        <Notes />
        <Footer />
        <MobileMenu />
      </div>
    );
};

export default ScreenSix;