
// import React from 'react';
// import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {React, lazy, Suspense} from 'react';

/*
* @ All pages Import
*/
// import HomeTwo from './pages/HomeTwo'
// import Service from './pages/Service'
// import ServiceDetails from "./pages/ServiceDetails";
// import BlogGridRightSidebar from './pages/BlogGridRightSidebar';
// import BlogGridLeftSidebar from './pages/BlogGridLeftSidebar';
// import BlogGridWithoutSidebar from './pages/BlogGridWithoutSidebar';
// import BlogListLeftSidebar from './pages/BlogListLeftSidebar';
// import BlogListRightSidebar from './pages/BlogListRightSidebar';
// import BlogDetailsPage from "./pages/BlogDetails";
// import Team from "./pages/Team";
// import TeamDetails from "./pages/TeamDetails";
// import About from "./pages/About";
// import Contact from "./pages/Contact";

// import HomeOne from "./pages/HomeOne";
// import Error404 from "./pages/Error404";
// import ScrollToTop from "./helpers/ScrollToTop";
// import ScreenTwo from './pages/ScreenTwo';
// import News from './pages/news';
// import Resources from './pages/resources';
// import Payments from './pages/Payments';
// import Practices from './pages/Practices';
// import AboutUs from './pages/AboutUs';
// import Enterprise from './pages/TenjinEnterprise';
// import TenjinOnline from './pages/TenjinOnline';
// import TenjinAI from './pages/TenjinAI';
// import TenjinVault from './pages/TenjinVault';
// import TenjinTM from './pages/TenjinTM';
// import Consulting from './pages/Consulting';
// import TCOE from './pages/TCOE';
// import Testing from './pages/TestingService';
// import Partner from './pages/Partner';
// import ContactUs from './pages/contactUs';
// import CoreBanking from './pages/CoreBanking';
// import Privacy from './pages/privacy';
// import TermsCondition from './pages/TermsCondition';
// import Lending from './pages/Lending';
// import TransactionBanking from './pages/TransactionBanking';
// import DigitalBanking from './pages/DigitalBanking';
import ScreenSix from './pages/ScreenSix';

// Lazy Loading 
const HomeOne = lazy(() => import('./pages/HomeOne'));
const Error404 = lazy(() => import('./pages/Error404'));
const ScreenTwo = lazy(() => import("./pages/ScreenTwo"));
// const ScreenSix = lazy(() => import("./pages/ScreenSix"));
const News = lazy(() => import("./pages/news"));
const Resources = lazy(() => import("./pages/resources"));
const Payments = lazy(() => import("./pages/Payments"));
const Practices = lazy(() => import("./pages/Practices"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const Enterprise = lazy(() => import("./pages/TenjinEnterprise"));
const TenjinOnline = lazy(() => import("./pages/TenjinOnline"));
const TenjinAI = lazy(() => import("./pages/TenjinAI"));
const TenjinVault = lazy(() => import("./pages/TenjinVault"));
const TenjinTM = lazy(() => import("./pages/TenjinTM"));
const Consulting = lazy(() => import("./pages/Consulting"));
const TCOE = lazy(() => import("./pages/TCOE"));
const Testing = lazy(() => import("./pages/TestingService"));
const Partner = lazy(() => import("./pages/Partner"));
const ContactUs = lazy(() => import("./pages/contactUs"));
const CoreBanking = lazy(() => import("./pages/CoreBanking"));
const Privacy = lazy(() => import("./pages/privacy"));
const TermsCondition = lazy(() => import("./pages/TermsCondition"));
const Lending = lazy(() => import("./pages/Lending"));
const TransactionBanking = lazy(() => import("./pages/TransactionBanking"));
const DigitalBanking = lazy(() => import("./pages/DigitalBanking"));
const ThankYou = lazy(() => import("./pages/thankYou"));

const App = () => {
    return (
        <BrowserRouter>
            {/* <ScrollToTop> */}
                <Routes>
                    {/* <Route exact path={`${process.env.PUBLIC_URL + '/services'}`} component={Service}/>
                    <Route path={`${process.env.PUBLIC_URL + '/service/:serviceID'}`} component={ServiceDetails}/>
                    <Route exact path={`${process.env.PUBLIC_URL + "/blog-grid-right-sidebar"}`}
                           component={BlogGridRightSidebar}/>
                    <Route exact path={`${process.env.PUBLIC_URL + "/blog-grid-left-sidebar"}`}
                           component={BlogGridLeftSidebar}/>
                    <Route exact path={`${process.env.PUBLIC_URL + "/blog-grid-without-sidebar"}`}
                           component={BlogGridWithoutSidebar}/>
                    <Route exact path={`${process.env.PUBLIC_URL + "/blog-list-left-sidebar"}`}
                           component={BlogListLeftSidebar}/>
                    <Route exact path={`${process.env.PUBLIC_URL + "/blog-list-right-sidebar"}`}
                           component={BlogListRightSidebar}/>
                    <Route path={`${process.env.PUBLIC_URL + "/blog/:blogID"}`} component={BlogDetailsPage}/>
                    <Route exact path={`${process.env.PUBLIC_URL + "/team"}`} component={Team}/>
                    <Route path={`${process.env.PUBLIC_URL + "/team-member/:teamID"}`} component={TeamDetails}/>
                    <Route exact path={`${process.env.PUBLIC_URL + "/about"}`} component={About}/>
                    <Route exact path={`${process.env.PUBLIC_URL + "/contact"}`} component={Contact}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/home-two'}`} component={HomeTwo}/> */}



                    
                    {/* <Route exact path='/' component={HomeOne}/>
                    <Route exact path='/index' component={HomeOne}/>
                    <Route exact path='/insights' component={ScreenTwo}/>
                    <Route exact path='/careers' component={ScreenSix}/>
                    <Route exact path='/news' component={News}/>,
                    <Route exact path='/resources' component={Resources}/>
                    <Route exact path='/payments' component={Payments}/>
                    <Route exact path='/practices/payments' component={Practices}/>
                    <Route exact path='/about-us' component={AboutUs}/>
                    <Route exact path='/tenjin-enterprise' component={Enterprise}/>
                    <Route exact path='/tenjin-online' component={TenjinOnline}/>
                    <Route exact path='/tenjin-ai' component={TenjinAI}/>
                    <Route exact path='/tenjin-vault' component={TenjinVault}/>
                    <Route exact path='/tenjin-task-manager' component={TenjinTM}/>
                    <Route exact path='/consulting' component={Consulting}/>
                    <Route exact path='/tcoe' component={TCOE}/>
                    <Route exact path='/testing-services' component={Testing}/>
                    <Route exact path='/partner' component={Partner}/>
                    <Route exact path='/contact' component={ContactUs}/>
                    <Route exact path='/core-banking' component={CoreBanking}/>
                    <Route exact path='/privacy-policy' component={Privacy}/>
                    <Route exact path='/terms-&-Condition' component={TermsCondition}/>
                    <Route exact path='/lending' component={Lending}/>
                    <Route exact path='/transaction-banking' component={TransactionBanking}/>
                    <Route exact path='/digital-banking' component={DigitalBanking}/>
            
                    <Route exact component={Error404}/> */}


                     <Route  path="/" element={<Suspense><HomeOne /></Suspense>} />
                     <Route  path="/index" element={<Suspense><HomeOne /></Suspense>} />
                     <Route  path="/insights" element={<Suspense><ScreenTwo /></Suspense>} />
                     <Route  path="/careers" element={<ScreenSix />} />
                     <Route  path="/news" element={ <Suspense><News /></Suspense>} />
                     <Route  path="/resources" element={ <Suspense><Resources /></Suspense>} />
                     <Route  path="/payments" element={<Suspense><Payments /></Suspense>} />
                     <Route  path="/practices/payments" element={<Suspense><Practices /></Suspense>} />
                     <Route  path="/about-us" element={ <Suspense><AboutUs /></Suspense>} />
                     <Route  path="/tenjin-enterprise" element={<Suspense><Enterprise /></Suspense>} />
                     <Route  path="/tenjin-online" element={<Suspense><TenjinOnline /></Suspense>} />
                     <Route  path="/tenjin-ai" element={<Suspense><TenjinAI /></Suspense>} />
                     <Route  path="/tenjin-vault" element={ <Suspense><TenjinVault /></Suspense>} />
                     <Route  path="/tenjin-task-manager" element={ <Suspense><TenjinTM /></Suspense>} />
                     <Route  path="/consulting" element={ <Suspense><Consulting /></Suspense>} />
                     <Route  path="/tcoe" element={<Suspense><TCOE /></Suspense>} />
                     <Route  path="/testing-services" element={ <Suspense><Testing /></Suspense>} />
                     <Route  path="/partner" element={ <Suspense><Partner /></Suspense>} />
                     <Route  path="/contact" element={ <Suspense><ContactUs /></Suspense>} />
                     <Route  path="/core-banking" element={ <Suspense><CoreBanking /></Suspense>} />
                     <Route  path="/privacy-policy" element={ <Suspense><Privacy /></Suspense>} />
                     <Route  path="/terms-&-condition" element={<Suspense><TermsCondition /></Suspense>} />
                     <Route  path="/lending" element={ <Suspense><Lending /></Suspense>} />
                     <Route  path="/transaction-banking" element={<Suspense><TransactionBanking /></Suspense>}/>
                     <Route  path="/digital-banking" element={<Suspense><DigitalBanking /></Suspense>} />
                     <Route  path="/thankyou" element={<Suspense><ThankYou /></Suspense>} />
                     <Route  path="*" element={<Suspense><Error404 /></Suspense>} />                
                </Routes>
            {/* </ScrollToTop> */}
        </BrowserRouter>
    );
};

export default App;