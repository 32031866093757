import React from "react";
import Icon1 from "../../assets/img/screen-six/why-1.svg";
import Icon2 from "../../assets/img/screen-six/why-2.svg";
import Icon3 from "../../assets/img/screen-six/why-3.svg";
import Icon4 from "../../assets/img/screen-six/why-4.svg";
import Icon5 from "../../assets/img/screen-six/why-5.svg";
import "../componant.css";

function Why(){
    return (
      <div className="mt-5" style={{ backgroundColor: "#eff2f6" }}>
        <div className="container pb-5">
          <div className="why-left">
            <h3 className="title-color pt-5">Why Yethi</h3>
            <div className="row">
              <div className="col-sm-4 col-12 why why-col mt-5">
                <div className="d-flex align-items-center justify-content-start mb-3 gap-3 online-why">
                  <img src={Icon1} alt="" />
                  <h5>Inclusion</h5>
                </div>
                <p>
                  A diverse and inclusive environment that welcomes people from
                  different backgrounds, perspectives, and experiences.
                </p>
              </div>
              <div className="col-sm-4 col-12 why-col mt-5">
                <div className="d-flex align-items-center justify-content-start mb-3 gap-3 online-why">
                  <img src={Icon2} alt="" />
                  <h5>Innovation</h5>
                </div>
                <p>
                  A leading provider of innovative solutions to the BFSI
                  industry, such as Tenjin– a highly resilient test automation
                  platform built for complex enterprise applications.
                </p>
              </div>
              <div className="col-sm-4 col-12 mt-5">
                <div className="d-flex align-items-center justify-content-start mb-3 gap-3 online-why">
                  <img src={Icon3} alt="" />
                  <h5>Teamwork</h5>
                </div>
                <p>
                  A collaborative and supportive team of BFSI experts, software
                  developers, quality assurance engineers, product managers, and
                  UX writers.
                </p>
              </div>
              <div className="col-sm-4 col-12 why-col mt-5">
                <div className="d-flex align-items-center justify-content-start mb-3 gap-3 online-why">
                  <img src={Icon4} alt="" />
                  <h5>Work-Life Balance</h5>
                </div>
                <p>
                  Competitive compensation, flexible work arrangements, and a
                  supportive work environment.
                </p>
              </div>
              <div className="col-sm-4 col-12 mt-5">
                <div className="d-flex align-items-center justify-content-start mb-3 gap-3 online-why">
                  <img src={Icon5} alt="" />
                  <h5>Growth-Oriented</h5>
                </div>
                <p>
                  A culture of learning, feedback, and recognition where
                  employees can grow and thrive.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
export default Why;